<template>
    <div v-if="isChangeOptionAvailable">
        <div
            class="h3 mb-small"
            data-qa="crefonummer-select-headline"
        >
            {{ $t('Components.ChooseCompany.Headline') }}
        </div>
        <p data-qa="crefonummer-select-description">
            {{ $t('Components.ChooseCompany.Description') }}
        </p>
        <div class="crefo-input crefo-ui-select">
            <div class="input-group">
                <label
                    for="crefonummer-select"
                    data-qa="crefonummer-select-label"
                >
                    <strong>
                        {{ $t('Components.ChooseCompany.Label') }}
                    </strong>
                </label>
                <div class="select-group">
                    <div
                        class="input-wrapper"
                        :class="{ disabled: isUpdating }"
                    >
                        <select
                            id="crefonummer-select"
                            v-model="value"
                            data-qa="crefonummer-select-liste"
                            :disabled="isUpdating"
                            :aria-disabled="isUpdating"
                        >
                            <option
                                value=""
                                data-qa="crefonummer-select-empty"
                            >
                                {{ $t('Components.ChooseCompany.PleaseChoose') }}
                            </option>
                            <option
                                v-for="(companyOption, index) in companyOptions"
                                :key="index"
                                :value="companyOption.crefonummer"
                                :data-qa="'crefonummer-select-' + index"
                            >
                                {{ companyOption.firmierung }}
                            </option>
                        </select>
                        <crefo-ring-loading
                            v-if="isUpdating"
                            class="loader"
                        />
                    </div>
                </div>
            </div>
        </div>
        <crefo-error-message
            v-if="ajaxError"
            :ajax-error="ajaxError"
        />
    </div>
</template>

<script>
    import { mapStores } from 'pinia'
    import { useUserStore } from '@/stores/user'
    import { useAbonnementStore } from '@/stores/abonnement'
    import { getKurzprofil, patchUser } from '@/services'
    import CrefoErrorMessage from "@dm/crefo-error-message"
    import CrefoRingLoading from '@dm/crefo-ring-loading'
    import WrapperWebtrekk from '@/helper/webtrekk-wrapper'
    import HelperUser from '@dm/helper-user'

    export default {
        name: 'ChooseCompany',
        components: { CrefoErrorMessage, CrefoRingLoading },
        data () {
            return {
                isLoading: true,
                isUpdating: false,
                companyOptions: [],
                ajaxError: null
            }
        },
        computed: {
            ...mapStores(useUserStore, useAbonnementStore),
            isChangeOptionAvailable () {
                return !this.isLoading &&
                    this.isRegisteredUser &&
                    this.hasActiveAbonnementsWithVbRoleAndDifferentCrefonummer
            },
            isRegisteredUser () {
                return HelperUser.isRegisteredUser()
            },
            hasActiveAbonnementsWithVbRoleAndDifferentCrefonummer () {
                return this.activeAbonnementsWithVbRoleAndDifferentCrefonummer.length > 0
            },
            activeAbonnementsWithVbRoleAndDifferentCrefonummer () {
                if (this.abonnementStore.abonnementsVertretungsberechtigt instanceof Array) {
                    return this.abonnementStore.abonnementsVertretungsberechtigt.filter(abonnement => {
                        return [ 'AKTIV', 'GEKUENDIGT' ].includes(abonnement.status) &&
                            abonnement.crefonummer !== this.userStore.crefonummer
                    })
                } else {
                    return []
                }
            },
            value: {
                get () {
                    return ''
                },
                set (newCrefonummer) {
                    if (typeof newCrefonummer === 'string' && newCrefonummer.length && newCrefonummer !== this.userStore.crefonummer) {
                        this.updateCrefonummer(newCrefonummer)
                    }
                }
            }
        },
        async mounted () {
            this.ajaxError = null
            const crefonumbers = this.getCrefonumbers()
            if (crefonumbers.length) {
                try {
                    this.companyOptions = await this.fetchCompanyNames(crefonumbers)
                } catch (error) {
                    console.log(error)
                } finally {
                    this.isLoading = false
                }
            }
        },
        methods: {
            getCrefonumbers () {
                const crefonumbers = []

                if (this.isRegisteredUser) {
                    if (this.hasActiveAbonnementsWithVbRoleAndDifferentCrefonummer) {
                        this.activeAbonnementsWithVbRoleAndDifferentCrefonummer.forEach(abonnement => {
                            if (typeof abonnement.crefonummer === 'string' && abonnement.crefonummer.length > 0) {
                                if (!crefonumbers.includes(abonnement.crefonummer)) {
                                    crefonumbers.push(abonnement.crefonummer)
                                }
                            }
                        })
                        if (crefonumbers.length > 0) {
                            WrapperWebtrekk.trackAction('meine_bonitaet_frontend_action_chooseCompany_visible')
                        }
                    }
                }

                return crefonumbers
            },
            async fetchCompanyNames (crefonumbers = []) {
                let loaded = 0
                const companyNames = []

                return new Promise(resolve => {
                    crefonumbers.forEach(crefonummer => {
                        getKurzprofil(crefonummer)
                            .then(response => {
                                let firmierung = crefonummer

                                if (typeof response?.data?.firmenname === 'string' && response.data.firmenname.length) {
                                    firmierung += ' - ' + response.data.firmenname
                                }
                                if (typeof response?.data?.adresse === 'object' && response.data.adresse !== null) {
                                    firmierung += ' ('
                                    if (typeof response?.data?.adresse?.plz === 'string' && response.data.adresse.plz.length) {
                                        firmierung += response.data.adresse.plz + ' '
                                    }
                                    if (typeof response?.data?.adresse?.ort === 'string' && response.data.adresse.ort.length) {
                                        firmierung += response.data.adresse.ort
                                    }
                                    firmierung += ')'
                                }

                                companyNames.push({
                                    crefonummer,
                                    firmierung
                                })
                                ++loaded
                            })
                            .catch(error => {
                                companyNames.push({
                                    crefonummer,
                                    firmierung: crefonummer
                                })
                                ++loaded
                            })
                            .finally(() => {
                                if (loaded >= crefonumbers.length) {
                                    resolve(companyNames)
                                }
                            })
                    })
                })
            },
            updateCrefonummer (newCrefonummer) {
                if (this.isUpdating) {
                    return
                }

                this.isUpdating = true
                this.ajaxError = null

                const patchObject = {
                    path: '/unverifiedCrefonummer'
                }

                WrapperWebtrekk.trackAction('meine_bonitaet_frontend_action_chooseCompany_update')
                if (typeof this.userStore.crefonummer === 'string' && this.userStore.crefonummer.length) {
                    if (typeof newCrefonummer === 'string' && newCrefonummer.length) {
                        patchObject.op = 'replace'
                        patchObject.value = newCrefonummer
                    } else {
                        patchObject.op = 'remove'
                    }
                } else if (typeof newCrefonummer === 'string' && newCrefonummer.length) {
                    patchObject.op = 'add'
                    patchObject.value = newCrefonummer
                } else {
                    window.location.href = window.location.origin + '/portal/meine-bonitaet'
                    return false
                }

                patchUser({ patchOperations: [ patchObject ] })
                    .then(response => {
                        WrapperWebtrekk.trackAction('meine_bonitaet_frontend_action_chooseCompany_update_success')
                        window.location.href = window.location.origin + '/portal/meine-bonitaet'
                    })
                    .catch(error => {
                        this.ajaxError = error
                        WrapperWebtrekk.trackAction('meine_bonitaet_frontend_action_chooseCompany_update_failed')
                    })
                    .finally(() => {
                        this.isUpdating = false
                    })
            }
        }
    }
</script>

<style scoped lang="less">
    .crefo-input {
        .input-group {
            .input-wrapper {
                position: relative;

                &.disabled {
                    cursor: default;
                    pointer-events: none;

                    ::v-deep(.select-selected) {
                        background-color: rgba(237, 236, 229, 0.5);
                        cursor: default;
                        user-select: none;
                        pointer-events: none;
                    }
                }

                .loader {
                    position: absolute;
                    top: 10px;
                }
            }
        }
    }
</style>
